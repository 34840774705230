
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import Detail from "./details.vue";

import {
  NavBar,
  Field,
  Popup,
  Picker,
  Cell,
  Switch,
  Row,
  Col,
  Image,
  Button,
  Tabs,
  Tab,
  List,
  Icon,
  Loading,
  Toast,
} from "vant";
@Component({
  components: {
    Mixin,
    Detail,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
  },
})
export default class Integral extends Mixins(Mixin) {
  public availableIntegral = 0; //可用积分
  public invalidIntegral = 0; //未生效积分
  public willExpired: member.WillExpiredDTO = {}; //即将过期
  public IntegralList: Array<member.IntegralIncomeDTO> = []; //获取积分
  public expendList: Array<member.IntegralExpendDTO> = []; // 消耗积分
  public status = "0"; //积分商城开通状态 0 未开通 1已开通
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 20,
  };
  active = 0;
  created(): void {
    this.getIntegral();
    this.getTakeIntegral(true);
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  onClickRight(): void {
    let name = "积分说明";
    let type = "WHAT_ARE_POINTS";
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
  changeTab(name: number, title: string): void {
    if (title === "消耗记录") {
      this.getIncomeIntegral(true);
    } else {
      this.getTakeIntegral(true);
    }
  }
  getIntegral(): void {
    this.$api.memberApi.integral.count(({ data }) => {
      this.availableIntegral = data.available!;
      this.invalidIntegral = data.invalid!;
      this.willExpired = data.willExpired!;
      this.status = data.mobileStatus!;
    });
  }
  getIncomeIntegral(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
    }
    this.list.loading = true;
    this.$api.memberApi.integral.getIncomePageList(
      {
        pageNum: this.page.current,
        pageSize: this.page.size,
      },
      ({ data }) => {
        Toast.clear();
        if (this.page.current === 1) {
          this.expendList = data.records!;
        } else {
          this.expendList = this.expendList.concat(data.records!);
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
      }
    );
  }
  getTakeIntegral(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      this.page.current++;
    }

    this.list.loading = true;
    this.$api.memberApi.integral.take(
      {
        pageNum: this.page.current,
        pageSize: this.page.size,
      },
      ({ data }) => {
        Toast.clear();
        if (this.page.current === 1) {
          this.IntegralList = data.records!;
        } else {
          this.IntegralList = this.IntegralList.concat(data.records!);
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
      }
    );
  }
  exchange(): void {
    this.$router.push("/mall");
  }
}
