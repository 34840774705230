
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  NavBar,
  Field,
  Popup,
  Picker,
  Cell,
  Switch,
  Row,
  Col,
  Image,
  Button,
  Tabs,
  Tab,
  Tag,
} from "vant";
import { integrals } from "@/shims.decorator";
@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Tag.name]: Tag,
  },
})
export default class Integral extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public IntegralList!: Array<integrals>;
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public expendList!: Array<integrals>;
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    // const ss = (dt.getSeconds() + "").padStart(2, "0");

    return `${y}.${m}.${d} ${hh}:${mm}`;
  }
}
